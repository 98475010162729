const siteUrl = 'https://www.rolls-roycemotorcars-houston.com/'

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: 'Nissan',
    siteUrl:'https://www.rolls-roycemotorcars-houston.com/',
    site_id: '6580ca4722df76d9eae8a345',
    api_base_url: 'https://api.dealermasters.com:4500',
    lead_url: 'https://leads.dealermasters.com/leads',
    master_sites: [],
    autocheck_url: 'https://n1u6ywp1bf.execute-api.us-east-2.amazonaws.com/default/AutoCheckFastLink',
      quickgenAPI: 'https://dev.mxsapi.com',
      imageBaseURL: 'https://images.mxssolutions.com'
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: "gatsby-plugin-force-trailing-slashes"
    },
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
      },
    },
    {
      resolve: "gatsby-plugin-emotion"
    },
    {
      resolve: "gatsby-transformer-json"
    },
    {
      resolve: "gatsby-plugin-sass",
      options: {
        cssLoaderOptions: {
          camelCase: false,
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /\.inline\.svg$/
        }
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `src`,
        path: `${__dirname}/src/`
      }
    },
    {
      resolve: 'gatsby-plugin-s3',
      options: {
        bucketName: 'mxs-5eed3551-6295-4b00-8c2b-056d0c844540-prodsite'
      }
    },
    `gatsby-transformer-json`,
    {
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'altInventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: ['03312022']//126024
      }
    },
    { 
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'inventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: ['219277']//126024
      }
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'static/favicon.png'
      }
    },{
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.rolls-roycemotorcars-houston.com/',
        sitemap: 'https://www.rolls-roycemotorcars-houston.com//sitemap-pages.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    }
  ]
}